import DOMPurify from 'dompurify';
import { urlify, cleanInternalLinks } from './string';

DOMPurify.addHook('afterSanitizeAttributes', (node) => {
	// set all elements owning target to target=_blank
	if ('target' in node) {
		if (node.getAttribute('target')) {
			// Making sure that it does have rel on target _blank.
			node.setAttribute('target', '_blank');
			// Prevent https://www.owasp.org/index.php/Reverse_Tabnabbing
			node.setAttribute('rel', 'noopener noreferrer');
			return;
		}

		let href = node.getAttribute('href');
		if (!hasClass(node, 'ignoreLink')) href = cleanInternalLinks(href);

		if (!/^(https?:|mailto:|tel:|\/\/)/.test(href) && !hasClass(node, 'linkCapture')) {
			addClass(node, 'linkCapture');
			node.setAttribute('href', href);
		}
	}
});

// Ideally whitelist URL's but this is better than nothing.
DOMPurify.addHook('beforeSanitizeElements', (node) => {
	if (node.nodeName === 'SCRIPT') {
		const src = node.getAttribute('src');
		if ('src' in node.attributes && typeof document !== 'undefined') {
			const scriptNode = document.createElement('script');
			scriptNode.src = src;
			scriptNode.className = 'js-embedScripts';
			scriptNode.id = urlify(src);
			// Check if the script has already run. Don't add again.
			if (document.getElementById(urlify(src))) return;
			document.head.appendChild(scriptNode);
		}
	}
});

DOMPurify.addHook('uponSanitizeElement', (node, data) => {
	if (data.tagName === 'script') {
		return node.parentNode.removeChild(node);
	}
});

const nameSpacedEvents = {};

const addEvent = (el, type, handler, options = {}) => {
	const [ev, namespace] = type.split('.');
	nameSpacedEvents[`${ev}_${namespace}`] = handler;

	if (el.attachEvent) {
		el.attachEvent(`on${ev}`, handler);
	} else {
		el.addEventListener(ev, handler, options);
	}
};

const removeEvent = (el, type, options = {}) => {
	const [ev, namespace] = type.split('.');

	if (!nameSpacedEvents[`${ev}_${namespace}`]) return;

	if (el.detachEvent) {
		el.detachEvent(`on${ev}`, nameSpacedEvents[`${ev}_${namespace}`]);
	} else {
		el.removeEventListener(ev, nameSpacedEvents[`${ev}_${namespace}`], options);
	}
};

// clientWidth = width *not* including padding.
// offsetWidth = width including padding.
// scrollWidth = overflow scrolling width.
const width = (el, widthType = true) => {
	if (el === window) {
		return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
	}
	if (typeof widthType === 'boolean') widthType = widthType ? 'offsetWidth' : 'clientWidth';
	return el[widthType];
};

// clientHeight = height *not* including padding.
// offsetHeight = height including padding.
// scrollHeight = overflow scrolling height.
const height = (el, heightType = true) => {
	if (el === window) {
		return window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
	}
	if (el === document) {
		return Math.max(document.body.scrollHeight, document.documentElement.scrollHeight);
	}
	if (typeof heightType === 'boolean') heightType = heightType ? 'offsetHeight' : 'clientHeight';
	return el ? el[heightType] : 0;
};

const offset = (el) => {
	if (!el) return { top: 0, left: 0 };
	const rect = el.getBoundingClientRect();
	const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
	const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
	return { top: Math.round(rect.top + scrollTop), left: Math.round(rect.left + scrollLeft) };
};

const stickyOffset = (el) => {
	if (!el) return { top: 0, left: 0 };
	const rect = el.getBoundingClientRect();
	return { top: Math.round(rect.top), left: Math.round(rect.left) };
};

const hasClass = (el, className) => {
	if (!el) return false;
	if (el.classList) {
		return el.classList.contains(className);
	}
	return new RegExp(`\\b ${className}\\b`).test(el.className);
};

const addClass = (el, className) => {
	if (!el) return;
	if (!Array.isArray(className)) className = [className];
	for (let i = 0; i < className.length; i += 1) {
		if (el.classList) el.classList.add(className[i]);
		else if (!hasClass(el, className)) el.className += ` ${className[i]}`;
	}
};

const removeClass = (el, className) => {
	if (!el) return;
	if (className === '') {
		el.className = '';
		return;
	}
	if (!Array.isArray(className)) className = [className];
	for (let i = 0; i < className.length; i += 1) {
		if (el.classList) el.classList.remove(className[i]);
		else el.className = el.className.replace(new RegExp(`\\b ${className[i]}\\b`, 'g'), '');
	}
};

const fixBackgroundScroll = (fixed) => {
	const $body = document.body;
	if (fixed && !hasClass($body, 'u_backgroundFixed')) {
		const scrollY = window.pageYOffset;
		$body.style.position = 'fixed';
		$body.style.top = `-${scrollY}px`;
		$body.style.overflow = 'hidden';
		addClass($body, 'u_backgroundFixed');
	} else if (!fixed && hasClass($body, 'u_backgroundFixed')) {
		const scrollY = $body.style.top;
		$body.style.position = '';
		$body.style.top = '';
		$body.style.overflow = '';
		window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
		removeClass($body, 'u_backgroundFixed');
	}
};

const scrollTo = (scrollDuration, scrollDestination) => {
	const cosParameter = (window.pageYOffset - scrollDestination) / 2;
	let scrollCount = 0;
	let oldTimestamp = window.performance.now();

	const step = (newTimestamp) => {
		scrollCount += Math.PI / (scrollDuration / (newTimestamp - oldTimestamp));
		if (scrollCount >= Math.PI) return;
		window.scrollTo(0, Math.round(scrollDestination + cosParameter + (cosParameter * Math.cos(scrollCount))));
		oldTimestamp = newTimestamp;
		window.requestAnimationFrame(step);
	};

	window.requestAnimationFrame(step);
};

// scrollEl
// scrollDuration in ms
// scrollDestination in percent of the total scrollable area.
const scrollElToX = (scrollEl, scrollDuration = 300, scrollDestination = 1) => (
	new Promise((resolve) => {
		const scrollToDestination = (scrollEl.scrollWidth - scrollEl.offsetWidth) * (1 - scrollDestination);
		const cosParameter = (scrollEl.scrollWidth - scrollEl.offsetWidth) / 2;
		let scrollCount = 0;
		let oldTimestamp = window.performance.now();

		const step = (newTimestamp) => {
			scrollCount += Math.PI / (scrollDuration / (newTimestamp - oldTimestamp));
			if (scrollCount >= Math.PI) {
				scrollEl.parentElement.scrollTo((scrollDestination === 0 ? 0 : scrollEl.scrollWidth - scrollEl.offsetWidth), 0);
				return resolve();
			}
			if (scrollDestination === 0) {
				scrollEl.parentElement.scrollTo(Math.round(scrollToDestination - cosParameter + (cosParameter * Math.cos(scrollCount))), 0);
			} else {
				scrollEl.parentElement.scrollTo(Math.round(cosParameter - (cosParameter * Math.cos(scrollCount))), 0);
			}
			oldTimestamp = newTimestamp;
			window.requestAnimationFrame(step);
		};

		window.requestAnimationFrame(step);
	})
);

const existsCookie = (cookieName) => {
	if (typeof document === 'undefined') return null;
	const regexpCheck = new RegExp(`\\s*${cookieName}\\s*=`);
	return document.cookie.indexOf(cookieName) !== -1 && regexpCheck.test(document.cookie);
};

const setCookie = (cookieName, cookieValue = 1, cookieDuration = 7) => {
	if (typeof document === 'undefined') return;
	const expires = `expires=${new Date(Date.now() + cookieDuration * 864e5)}`;
	const value = typeof cookieValue !== 'string' ? JSON.stringify(cookieValue) : cookieValue;
	document.cookie = `${cookieName}=${value}; ${expires}; path=/; SameSite=Lax`;
};

const getCookie = (cookieName, parseJSON = false) => {
	if (!existsCookie(cookieName) || typeof document === 'undefined') return null;
	let cookieValue = null;
	document.cookie.split(';').forEach((cookie) => {
		const regexpCheck = new RegExp(`^\\s*${cookieName}\\s*=`);
		if (regexpCheck.test(cookie)) {
			cookieValue = cookie.trim().split('=').slice(-1)[0].trim();
		}
	});
	if (parseJSON && cookieValue) {
		return JSON.parse(decodeURIComponent(cookieValue));
	}
	return cookieValue;
};

const deleteCookie = (cookieName) => {
	if (typeof document === 'undefined') return;
	document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; SameSite=Lax`;
};

const getAllCookies = (includeWithPrefix = false) => {
	if (typeof document === 'undefined') return {};
	const cookies = {};
	document.cookie.split(';').forEach((cookie) => {
		const [cookieKey, cookieValue] = cookie.trim().split('=');
		if (includeWithPrefix && cookieKey.indexOf(includeWithPrefix) !== 0) return;
		cookies[cookieKey.trim()] = cookieValue.trim();
	});
	return cookies;
};

const deleteAllCookies = (includeLocalStorage = false) => {
	if (typeof document === 'undefined') return;
	const cookies = getAllCookies();
	Object.keys(cookies).forEach((cookie) => deleteCookie(cookie));
	if (includeLocalStorage) localStorage.clear();
};

const getQuery = () => (
	window?.location?.search ? window.location.search.slice(1)
		.split('&')
		.map((query) => (
			query.split('=').map((p) => (
				window.decodeURIComponent(p).trim()
			))
		)).reduce((acc, p) => (
			{ ...acc, [p[0]]: p[1] }
		), {}) : {}
);

const purifier = (text, config) => (DOMPurify.sanitize(text, config));

export {
	addEvent,
	removeEvent,
	width,
	height,
	offset,
	stickyOffset,
	hasClass,
	addClass,
	removeClass,
	fixBackgroundScroll,
	scrollTo,
	scrollElToX,
	existsCookie,
	setCookie,
	getCookie,
	deleteCookie,
	getAllCookies,
	deleteAllCookies,
	getQuery,
	purifier,
};
