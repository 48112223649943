import { addToGift, purchase, abandonedCart } from '../shop/connect';
import ChangeCountry from '../checkout/change_country';
import Payment from '../checkout/payment';
import Validate from '../checkout/validate';
import { getCookie } from '../utils/dom';

const giftCert = {
	init: ($parent) => {
		const $scope = $parent || $(document);

		ChangeCountry.init($scope, 1);
		Validate.init($scope, 1);
		Payment.init($scope, 1);

		$(window).on('store.updated', () => {
			Validate.updateFields();

			$('#giftCert_order_proceed').prop('disabled', Validate.checkFields());
		});

		$scope.on('change', '#js-changeAmount select', function () {
			const value = $(this).find('option:selected').text();
			$scope.find('#js-giftCertAmount').text(value);
		});

		$scope.on('click', '.js-editSection', function () {
			const $this = $(this);
			const whichSection = $this.data('section');

			if (whichSection === 'address') {
				$('#js-summary').hide();
				$('#js-payment').hide();
				$('#js-selectionForm').show();
			}
		});

		$scope.on('submit', '#js-selectionForm', (e) => {
			e.preventDefault();

			// Set up abandoned cart tracking.
			abandonedCart()
				.then((response) => {
					if (response.success) {
						if (typeof window.va !== 'undefined' && response.id) {
							if (!getCookie('_vaI')) window.va('setContactId', response.id);
						}
					}
					return response;
				}).catch((err) => console.log('DEBUG: err', err));

			// Initiate gift selection.
			const value = $scope.find('#js-changeAmount select').val();
			addToGift(value)
				.then(() => (
					purchase(1)
						.then((response) => {
							$('#js-summary').show();
							$('#js-payment').show();
							$('#js-selectionForm').hide();

							return response;
						})
						.catch(() => {
							console.log('ERROR: was unable to initiate purchase');
						})
				))
				.catch((err) => console.log('ERROR: Problem displaying the rest of gift certificate checkout', err));
		});
	},
};

export default giftCert;
