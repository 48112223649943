import { addToBag } from '../shop/connect';
import { getCookie } from '../utils/dom';
import Continue from './continue';
import EditQuantity from './edit_quantity';
import Voucher from './voucher';
import Payment from './payment';
import Shipping from './shipping';
import ShippingAddress from './shipping_address';
import ChangeCountry from './change_country';
import Validate from './validate';

const Checkout = {
	init: () => {
		window.checkoutIsSuspended = false;
		window.checkoutUpdatingPaymentFields = false;

		Continue.init();
		EditQuantity.init();
		Voucher.init($('#js-selectionOrder'));
		Payment.init();
		Shipping.init();
		ShippingAddress.init();
		ChangeCountry.init();
		Validate.init();
		Validate.updateFields();

		const storeData = getCookie('store', true);
		let tempLanguage = storeData.language.toUpperCase();
		if (['SV', 'EN'].indexOf(tempLanguage) === -1) tempLanguage = 'EN';

		window.charityFunnel = {
			store: window.storeSettings.charityFunnel || '',
			info: {
				lang: tempLanguage,
				currency: storeData.currency.toUpperCase(),
			},
			cart: [],
			addProduct: (data) => {
				addToBag(data.item);
			},
		};

		$(window).on('store.updated', () => {
			Validate.updateFields();

			$('#checkout_order_proceed').prop('disabled', Validate.checkFields());
		});

		$(document).on('click', '.js-toggle', function (e) {
			e.preventDefault();
			$(this).parent().toggleClass('is-toggleOpen');
		});

		$(document).on('keydown', (e) => {
			if (e.keyCode === 13 && e.target.nodeName === 'INPUT') e.preventDefault();
		});
	},
};

export default Checkout;
